import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api } from "../utils/constants/url";
import { Mutex } from "async-mutex";
import { ROUTES } from "../utils/constants/variables/routes";
// import { setCurrentUser } from "../redux/slices/authSlice";

// export const baseQueryWithAuthInterceptor = (args) => {
//     const baseQuery = fetchBaseQuery(args);
//     return async (args, api, extraOptions) => {
//         const result = await baseQuery(args, api, extraOptions);
//         return result;
//     };
// };

export const prepareHeaders = (headers, { getState }) => {
  const token = getState().auth.token || window.localStorage.getItem("token");
  if (token) headers.set("authorization", `Bearer ${token}`);
  return headers;
};

// Create a new mutex
const mutex = new Mutex();
export const baseQueryWithAuthInterceptor = (args) => {
  // Wait until the mutex is available without locking it
  const baseQuery = fetchBaseQuery(args);
  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    await mutex.waitForUnlock();
    // Check if the error status is 401 (Unauthorized)
    if (
      result.error &&
      (result.error.status === 401 || result.error.status === 419)
    ) {
      // Check if the mutex is not locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          // Call the refreshToken function to refresh the token
          await refreshToken();
          result = await baseQuery(args, api, extraOptions);
        } finally {
          // Release the mutex
          release();
        }
      } else {
        // Wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }

    return result;
  };
};

// Define a function to refresh the token
const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    console.error("Refresh token not found");
    window.location.href = ROUTES.HOME;
    return;
  }
  try {
    const payload = {
      refreshToken: refreshToken,
    };
    await fetch(api.baseURL + "/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          localStorage.removeItem("mobileNumber");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          window.location.href = ROUTES.HOME;
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data?.status === 202) {
          localStorage.setItem("token", data?.token);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        localStorage.removeItem("mobileNumber");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
      });
  } catch (error) {
    localStorage.removeItem("mobileNumber");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    console.error("Error refreshing token:", error);
    window.location.href = ROUTES.HOME;
  }
};
// const refreshToken = async () => {
//     const [refToken] = useTokenMutation();
//     const response = await refToken();
//     if (response?.status === 202) {
//         localStorage.setItem("token", response?.token);
//     }
// };
